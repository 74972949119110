export enum CommonEnum {
    Success = 'Success',
    Version = "PrepStudy V2",
    Board = 'Board',
    Public = 'Public',
    Private = "Private",
    object = 'object',
    String = 'string',
    Institute = 'Institute',
    en = 'en',
    hindi = 'hi',
    English = 'English',
    Hindi = 'Hindi',
    Easy = 'Easy',
    Medium = 'Medium',
    Hard = 'Hard',
    OPTION_IMAGE = 'OPTION_IMAGE',
    BODY_IMAGE = 'BODY_IMAGE',
    exam = 'exam'
  }

export enum RoleEnum {
  PrepStudyAdmin = 'PrepStudy Admin'
  }

  export enum ModalPopupEnum {
    WARNING = 'WARNING',
    CONFIRMATION = 'CONFIRMATION',
    Error = 'Error',
    ALERT = 'ALERT',
    DELETE = 'DELETE',
    SUCCESS = 'SUCCESS',
    WAIT_WARNING = 'WAIT_WARNING',
    DAILY_CHALLENGE = 'DAILY_CHALLENGE',
  }

  export enum ExamCategory {
    Board = 'B',
  }
  

  export enum ErrorMessageEnum {
    InValidFile = 'Invalid file, Please select valid file',
    FileSize = 'File Size must be less then 1 MB',
    NotSelectMoreThan = 'File Size must be less then 1 MB',
    MaxSizeImage='File size exceeds 1 MB.'
  }

  export enum QuestionTypeListEnum {
    MCQ = 'MCQ',
    TF = 'TF',
    ES = 'ES',
    True_False = 'True/False',
    Essay = 'Essay',
  }
  
  export enum Status{
    A = "A",
    Active = "Active",
    I = "I",
    Inactive = "Inactive"
  }

  export enum BinaryEnum {
    True = 'True',
    False = 'False',
  }

  export enum AnswerEnum {
    Yes = 'Yes',
    No = 'No',
  }
  
export enum DifficultyDistributionTypeEnum {
  Easy = 'E',
  Medium = 'M',
  Hard = 'H',
}

export enum difficultyLevelEnum {
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
  E = 'E',
  M = 'M',
  H = 'H',
}

 export enum CustomerTypeEnum {
  Student = 'Student',
 }

 export enum BillingCycleEnum {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Custom = 'Custom',
  Days = 'Days'
 }
 
 export enum InstituteType {
  School = 'School',
  College = 'College',
  University = 'University',
  TrainingCenter = 'Training Center',
  Training_Center = 'Training_Center'
 }
  
 export enum InstituteCategory {
  Public = 'Public',
  Private = 'Private',
  SemiGovernment = 'Semi-government',
  Semi_Government = 'Semi_government'
 }
  
 export enum OrderingEnum {
  idDesc = '-id',
 }

 export enum Settings{
  instituteType= 'institute_type',
  instituteCategory = 'institute_category',
  agreementStart = 'agreement_start',
  agreementEnd = 'agreement_end',
  questionVisibility = 'question_visibility',
  totalExams = 'total_exams',
  concurrencyLevel = 'concurrency_level'
 }